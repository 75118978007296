<template>
  <div class="view-header" v-if="report?.designation">
    <div class="view-header__footer">
      <h1 class="page-title" v-html="report?.designation"></h1>
    </div>
  </div>

  <div class="view-body">
    <Section>
      <Container>
        <KeyValue label="Etat" >
          <Tag v-if="report.finalise" text="Disponible" color="success" />
          <Tag v-if="!report.finalise && !report.erreur" text="En cours" color="warning" />
          <Tag v-if="!report.finalise && report.erreur" text="En erreur" color="error" />
        </KeyValue>

        <KeyValue label="Details erreur" :value="report.details_erreur"/>

        <template v-for="modalite in modalites" :key="modalite.id">
          <KeyValue  label="Nom" :value="modalite.designation"/>
        </template>

      </Container>
    </Section>
  </div>

  <div class="view-footer">
    <div class="view-actions">

    </div>
  </div>
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Tag from '@/components/base/Tag.vue'
import KeyValue from '@/components/form/KeyValue.vue'

export default {
  name: 'EssaiFixedReportView',

  components: {
    Container,
    Section,
    Tag,
    KeyValue,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      protocol: {},
      report: {},
      modalites: {},
      repetitions: {},
      variables: {},
      variableCalculee: {},
    }
  },

  mounted() {
    this.getEssai()
    this.getRapport()
  },

  methods: {
    getEssai() {
      this.fetchService.get(`essai/${this.$route.params.id}`).then((response) => {
        this.protocol = response.data
      })
    },

    async getRapport() {
      const reportResponse = await this.fetchService.get(`essai/${this.$route.params.id}/rapport/${this.$route.params.rfid}`)
      const reportModaliteResponse = await this.fetchService.get(`essai/${this.$route.params.id}/rapport/${this.$route.params.rfid}/modalite`)
      const reportRepetitionResponse = await this.fetchService.get(`essai/${this.$route.params.id}/rapport/${this.$route.params.rfid}/repetition`)
      const reportVariableResponse = await this.fetchService.get(`essai/${this.$route.params.id}/rapport/${this.$route.params.rfid}/variable`)
      const reportVariableCalculeeResponse = await this.fetchService.get(`essai/${this.$route.params.id}/rapport/${this.$route.params.rfid}/variable_calculee`)

      console.log(reportModaliteResponse, reportRepetitionResponse, reportVariableResponse, reportVariableCalculeeResponse)

      this.report = reportResponse.data
      this.modalites = reportModaliteResponse.data
      this.repetitions = reportRepetitionResponse.data
      this.variables = reportVariableResponse.data
      this.variableCalculee = reportVariableCalculeeResponse.data
    },

  },
}
</script>

<style scoped></style>
